$primary: #fd7e14;
$primary-text: #fd7e14;

$alert-bg-scale: 0%;
$alert-border-scale: 0%;
$alert-color-scale: 0%;

@import 'bootstrap/scss/bootstrap';

// @for $i from 1 through 6 {
//     $zIndexBackdrop:  #{1000 + (5 * $i)};
//     $zIndexContent:  #{1000 + (5 * $i) + 2};
//     .modal-backdrop.show:nth-of-type(#{$i}) {
//       z-index: $zIndexBackdrop;
//     }
//     div[role="dialog"][aria-modal="true"]:nth-of-type(#{$i}) {
//       z-index: $zIndexContent;
//     }
// }

.btn-primary {
    color: $white !important;
}

.main-container {
    @extend .pt-2;
    @extend .pb-5;
    background: linear-gradient(180deg, $gray-100 80%, $gray-200);
    border-left: 1px solid $gray-400;
    border-right: 1px solid $gray-400;
    min-height: 500px;
}

body {
    background-color: $gray-200;
}

.navbar {
    border-bottom: 1px solid $orange-600;
}

a.nav-link.active {
  font-weight: bold;
}

.disabled-menu-item {
    @extend .nav-link;
    @extend .text-muted;
}

.impersonation-header {
    text-align: center;
    color: $white;
    background-color: $red;
    border-bottom: 1px solid $red-600;
}

.btn:disabled{
    opacity: 0.25;
}

.rounded-ends {
    border-top-right-radius: $btn-border-radius !important;
    border-bottom-right-radius: $btn-border-radius !important;
}

.pre-line {
    white-space: pre-line;
}

.small-text {
    font-size: 0.75em;
}

a.nav-bar-link {
    @extend .ms-1;
    @extend .me-1;
    @extend .navbar-text;
    text-decoration: none;
}

a.nav-bar-link.active {
    color: $white;
}